.App {
	text-align: center;
}

.App-logo {
	height: 15vmin;
	pointer-events: none;
}
.noScroll {
	overflow: hidden;
}
.App-link {
	color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 0.7s ease-in-out;
	}
}

.basePage {
	background-color: #282c34;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
.fullPage {
	height: 100vh;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.hero-text {
	font-size: 8vw;
	margin-bottom: 5vh;
}

@media screen and (min-width: 5000px) {
	.hero-text {
		font-size: 400px;
	}
}

.button {
	display: inline-block;
	padding: 15px 25px;
	font-size: 24px;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	outline: none;
	color: #fff;
	background-color: #683334;
	border: none;
	border-radius: 15px;
	box-shadow: 0 9px #999;
}

.button:hover {
	background-color: #53292a;
}

.button:active {
	background-color: #53292a;
	box-shadow: 0 5px #666;
	transform: translateY(4px);
}
button:focus {
	outline: none;
}

#toggleButton {
	z-index: 200;
	place-self: center;
	position: absolute;
	top: calc(100vh - (40px + 2vmin + 10vh));
	right: 0;
	left: 0;
	width: calc(40px + 2vmin);
	height: calc(40px + 2vmin);
	margin: auto;
	transition: bottom 0.6s, top 0.6s, transform 0.3s;
}

.toggleTransform {
	top: 3vh !important;
	transform: rotate(180deg);
}

.buttonPage {
	transition: 0.6s;
	-webkit-transition: 0.6s;
	height: 100vh;
	padding-bottom: calc(40px + 2vmin + 10vh);
}
.statPage.clicked {
	opacity: 1;
	height: 100vh;
	padding-top: calc(40px + 2vmin + 6vh);
}

.buttonPage.clicked {
	transform: translateY(-100vh);
	opacity: 0;
	height: 0;
	padding: 0;
}
.statPage {
	transition: 0.6s;
	-webkit-transition: 0.6s;
	opacity: 0;
	height: 0;
	padding: 0;
}
